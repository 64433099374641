<template>
    <div class="threestopStats">
        <proTitle></proTitle>
        <template v-if="showAll">
            <el-form :inline="true" :model="formInline" class="demo-form-inline flex-align-between">
                <div class="formInline-left">
                    <el-cascader size="mini" :options="userGroupOptionList"
                        :props="{ multiple: true, value: 'id', emitPath: false }" clearable filterable
                        v-model="formInline.userGroup" @change="userGroupChange" collapse-tags placeholder="请选择用户组">
                    </el-cascader>
                </div>
                <div class="download-inline">
                    <span class="export-btn csp flex-align" style="margin-right:10px" @click="exportFile">
                        <i class="el-icon-download" style="font-size: 17px;"></i>
                        下载
                    </span>
                </div>
            </el-form>
            <div class="my-table" v-loading="loading" element-loading-spinner="el-icon-loading">
                <table border="1">
                    <thead>
                        <tr>
                            <th>序号</th>
                            <th>环节</th>
                            <th class="td-address">盟市</th>
                            <th>人数</th>
                            <!-- 考勤完成率 = 完成考勤总次数/人数*培训天数*2 -->
                            <th>考勤完成率</th>
                            <th>平均综合得分</th>
                            <th>作业/文档数量</th>
                            <!-- 观/听平均参与率 = 观/听平均参与数量/人数*培训天数 -->
                            <th>观/听平均参与率</th>
                            <th>思平均参与率</th>
                            <th>问平均参与率</th>
                            <th>思&问相关率</th>
                            <th>共性问题频次</th>
                            <th class="td-quest">共性问题</th>
                        </tr>
                    </thead>
                    <tbody v-if="allData.length > 0">
                        <template v-for="(item, index) in allData">
                            <tr v-for="(items, indexs) in item.dataList">
                                <td :rowspan="allData[0].dataList.length" v-if="indexs == 0">{{ index + 1 }}</td>
                                <td :rowspan="allData[0].dataList.length" v-if="indexs == 0">{{ item.stageId == 1 ? '跟岗' :
                                    item.stageId == 2 ? '集中' : '返岗' }}</td>
                                <td @click="showDetail(item.areaId)">{{ items.areaName }}</td>
                                <td>{{ items.peopleCount }}</td>
                                <td>{{ (items.joinCount / items.peopleCount * item.sumDayNum * 2).toFixed(2) }}%</td>
                                <td>{{ (items.askCount + items.thinkCount + items.viewAndListenCount) * 20 +
                                    (items.joinCount) * 10 }}</td>
                                <td>/</td>
                                <td>{{ items.viewAndListenCount / items.peopleCount * item.sumDayNum }}%</td>
                                <td>{{ items.thinkCount / items.peopleCount * item.sumDayNum }}%</td>
                                <td>{{ items.askCount / items.peopleCount * item.sumDayNum }}%</td>
                                <td>/</td>
                                <td>/</td>
                                <td :rowspan="allData[0].dataList.length" v-if="indexs == 0">
                                    大单元：如何进行打单员教学设计<br>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>

        </template>
        <template v-else>
            <el-form :inline="true" :model="formInline" class="demo-form-inline flex-align-between">
                <div class="formInline-left">
                    <el-select v-model="formInline.value" placeholder="请选择" size="mini" @change="changeValue">
                        <el-option v-for="item in options" :key="item.stageId" :label="item.stageName" :value="item.stageId">
                        </el-option>
                    </el-select>
                </div>
                <div class="formInline-right flex-align-between">
                    <el-date-picker size="mini" v-model="formInline.datetimerange" type="datetimerange"
                        start-placeholder="开始日期" range-separator="至" end-placeholder="结束日期" prefix-icon="el-icon-date"
                        @change="filterChange2" value-format="timestamp" :editable="false" class="date-picker">
                    </el-date-picker>
                </div>
                <div class="download-inline flex-align">
                    <span class="export-btn csp flex-align" style="margin-right:10px" @click="exportFile">
                        <i class="el-icon-download" style="font-size: 17px;"></i>
                        下载
                    </span>
                    <span class="export-btn csp flex-align" style="margin-right:10px" @click="showAll = true">
                        <i class="el-icon-back" style="font-size: 17px;"></i>
                        返回
                    </span>
                </div>
            </el-form>
            <el-table :data="tableData2" border style="width: 100%; margin-top: 20px">
                <el-table-column type="index" label="序号" width="50" align="center">
                </el-table-column>
                <el-table-column prop="projectName" label="项目" width="180" align="center">
                </el-table-column>
                <el-table-column prop="areaName" label="盟市" align="center">
                </el-table-column>
                <el-table-column prop="schoolName" label="学校" width="180" align="center">
                </el-table-column>
                <el-table-column prop="roleName" label="角色" align="center">
                </el-table-column>
                <el-table-column prop="userName" label="姓名" align="center">
                </el-table-column>
                <el-table-column prop="phone" label="账号" align="center">
                </el-table-column>
                <el-table-column prop="viewAndListenNumber" label="观/听" align="center">
                </el-table-column>
                <el-table-column prop="thinkNumber" label="思" align="center">
                </el-table-column>
                <el-table-column prop="askNumber" label="问" align="center">
                </el-table-column>
                <el-table-column prop="other" label="其他" align="center">
                </el-table-column>
                <el-table-column prop="thinkAndAskRelNumber" label="思&问相关性" align="center">
                </el-table-column>
                <el-table-column prop="isAttendance" label="是否考勤" align="center">
                </el-table-column>
                <el-table-column prop="score" label="学员综合得分" align="center">
                </el-table-column>
                <el-table-column prop="keyword" label="关键词" width="180" align="center">
                </el-table-column>
                <el-table-column prop="keywords" label="关键词与本次培训哪些高频词的重合" width="180" align="center">
                </el-table-column>
            </el-table>
        </template>
    </div>
</template>
<script>
import {
    mapState
} from "vuex";
export default {
    data() {
        return {
            showAll: true,
            formInline: {
                datetimerange: [],
                value: 1,
                userGroup: []
            },
            options: [],
            tableData2: [],
            userGroupOptionList: [],
            stepData1: [],
            allData: [],
            loading:false
        }
    },
    computed: {
        ...mapState({
            currentProject: state => state.user.currentProject,
        })
    },
    methods: {
        exportFile() {

        },
        // 总体统计
        async queryAllStatistics() {
            this.loading = true;
            let data = {
                projectId: this.currentProject.id,
                userGroupIds: this.formInline.userGroup.toString()
            }
            let resData = await this.$Api.kimi.queryAllStatistics(data);
            console.log('三段式统计整体:', resData);

            resData.data.map(item => {
                let allObj = {
                    areaId: '',
                    areaName: "总体",
                    askCount: 0,
                    joinCount: 0,
                    peopleCount: 0,
                    thinkAndAskRelCount: 0,
                    thinkCount: 0,
                    viewAndListenCount: 0
                }
                item.dataList.map(items => {
                    allObj.askCount += items.askCount;
                    allObj.joinCount += items.joinCount;
                    allObj.peopleCount += items.peopleCount;
                    allObj.thinkAndAskRelCount += items.thinkAndAskRelCount;
                    allObj.thinkCount += items.thinkCount;
                    allObj.viewAndListenCount += items.viewAndListenCount;
                })
                item.dataList.unshift(allObj);
            })

            this.allData = resData.data;
            this.loading = false;
        },
        // 单个地区统计
        async queryStageStatistics() {
            let data = {
                projectId: this.currentProject.id,
                areaId: 3132,
                stage: this.formInline.value,
                startTime: this.formInline.datetimerange[0],
                endTime: this.formInline.datetimerange[1]
            }
            let resData = await this.$Api.kimi.queryStageStatistics(data);
            console.log('三段式统计单个地区:', resData);
            this.tableData2 = resData.data
        },
        // 获取用户组列表
        async getUserGroupOptionList() {
            let resData = await this.$Api.Form.getUserGroup(this.currentProject.id)
            // console.log('用户组列表', resData)
            this.userGroupOptionList = resData.data;
        },
        // 用户组选中变更
        userGroupChange(val) {
            console.log(val)
            if (!val || JSON.stringify(val) == '[]') {
                return;
            }
            this.formInline.userGroup = val;
            this.queryAllStatistics();
        },
        // 选择日期(总体)
        filterChange() {

        },
        // 选择日期(单个地区)
        filterChange2(date) {
            console.log('选择日期:', date)
        },
        // 获取阶段
        async queryProjectStageList() {
            let data = {
                projectId: this.currentProject.id
            }
            let resData = await this.$Api.kimi.queryProjectStageList(data);
            // console.log('获取阶段:',resData);
            this.options = resData.data
        },
        // 选择阶段
        changeValue(val) {
            console.log('选择阶段:', val);
            this.formInline.value = val;
            this.queryStageStatistics()
        },
        // 
        showDetail(){
            this.showAll = false
        }
    },
    mounted() {
        this.getUserGroupOptionList();
        this.queryAllStatistics();
        this.queryStageStatistics();
        this.queryProjectStageList()
    }
}
</script>
<style lang="less" scoped>
.threestopStats {
    padding: 20px 25px;
    box-sizing: border-box;
    overflow-x: auto;

    .demo-form-inline {
        margin: 15px 0;

        .date-picker {
            width: 320px;
        }

        @media screen and (min-width:1250px) {
            margin: 20px 0;

            .date-picker {
                width: 400px;
            }
        }
    }

    .download-inline {

        .export-btn {
            color: #595959;
            font-size: 14px;
        }
    }

    .my-table {
        width: 100%;
        overflow-x: auto;

        table {
            width: 100%;
            table-layout: fixed;
            border-color: #ebebeb;

            th {
                width: 80px;
                text-align: center;
                color: #606266;
            }

            .td-address,
            .td-compute {
                width: 200px;
            }

            .td-quest {
                width: 300px;
            }

            tbody {
                tr {
                    text-align: center;
                    color: #606266;
                }
            }
        }
    }

}
</style>